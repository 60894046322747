@import '~bootstrap/dist/css/bootstrap.min.css';
@import "~@ng-select/ng-select/themes/default.theme.css";
@import '~@fortawesome/fontawesome-free/css/all.css';
@import '@angular/material/prebuilt-themes/deeppurple-amber.css';
@import "~quill/dist/quill.snow.css";
@import "~toastr/build/toastr.css";



/* @import "compass/css3"; */
body {
  font-family: Arial, Helvetica, sans-serif !important;
  color: #657376;
  font-weight: normal;
}

.light-grey {
  color: #b4b4b4;
}

.blue-color {
  color: #195fbd;
}

.white {
  color: #FFFFFF !important;
}

.grey {
  color: #b4b4b4 !important;
}

.btn-blue {
  background: #afb2b4;
  /* Old browsers */
  background: -moz-linear-gradient(top, #3892f3 0%, #2251b9 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #3892f3 0%, #2251b9 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #3892f3 0%, #2251b9 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradientgt(startColorstr='#3892f3', endColorstr='#2251b9', GradientType=0);
  /* IE6-9 */
  color: #FFFFFF !important;
}

.alert {
  position: fixed !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  width: 30rem;
}

.toast-center {
  position: fixed !important;
  top: 16%;
  left: 87%;
  transform: translate(-50%, -50%);
  /* z-index: 9999;
  width: 30rem; */
  /* max-width: 500px; */
}

.close {
  text-decoration: none;
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5;
}


a {
  cursor: pointer;
}

.fullScreen {
  width: 100%;
}

.breadcrumb {
  /* margin-top: 1rem; */
  padding-bottom: 1rem !important;
  padding-top: 1rem !important;
  padding-left: 6% !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.container-fluid {
  width: 95% !important;
}

.dropdown-content {
  position: absolute;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.bold {
  font-weight: bold;
}

.float-left {
  float: left;
}

.service-section {
  height: auto;
  /* margin: 18px 10px 18px 10px; */
  padding: 11px 0px 10px 17px;
  border-radius: 7px;
  background-color: #e9eaea;
}

.serviceLetter-section {
  width: 60px;
  height: 60px;
  margin: 0 18px 0 0;
  padding: 12px 18px 20px 20px;
  background-color: #fff;
  border-radius: 50%;
}

.serviceLetter {
  width: 15px;
  height: 17px;
  font-family: Arial;
  font-size: 24px;
  font-weight: bold;
  text-align: left;
  color: #000;
}

.serviceName {
  font-family: Arial;
  font-size: 20px;
  text-align: left;
  color: #000;
}

.MA {
  display: flex;
  justify-content: center;
  font-family: Arial;
  font-size: 30px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.59;
  letter-spacing: normal;
  text-align: center;
  color: #495256;
}

.Makeup-Artist {
  font-family: Arial;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  color: #050505;
}

.item {
  display: flex;
  align-items: center;
  margin: 0;
}

.item:hover {
  display: flex;
  align-items: center;
  background-color: #38b6ff;
}

.ng-image-slider .ng-image-slider-container .main {
  display: flex !important;
  justify-content: center !important;
  width: 90% !important;
}

.ng-image-slider {
  display: inline-block !important;
  position: relative !important;
  padding-left: 5% !important;
  height: 205px !important;
  width: 41% !important;
  margin-left: 25% !important;
}

.responsive>svg {
  position: relative !important;
  width: 35% !important;
  height: 100% !important;
  left: 30% !important;
}

round-progress {
  padding-bottom: 0% !important;
}

.ng-select .ng-select-container {
  overflow: auto !important;
  max-height: 70px !important;
}

.owl-carousel {
  margin: 10px auto;
  width: 95%;
  max-width: 95%;
  position: relative;
}

.owl-carousel .owl-nav.disabled{
  display: block !important;
}

.owl-carousel .owl-stage {
  max-height: 450px;
  display: flex;
  align-items: center;
  overflow: hidden;
  border: 10px;
}

.carousel_2 .current {
  border: 2px solid #639;
  box-sizing: border-box;
  transition: box-shadow 0.3s ease-in-out; 
}

.carousel_2 .current img {
  transform-style: flat !important;
  border-radius: 10px;
}

.carousel_2 .active.center+.active,
.carousel_2 .active:first-child,
.carousel_2 :not(.active)+.active {
  max-width: 360px !important;
  height: 360px !important;
  max-width: 100% !important;
  max-height: 100% !important;
  background-color: black !important;
  /* opacity: 0.2; */
  overflow: hidden;
  border-radius: 20px !important;
  /* border: 2px solid red; */
}

.carousel_2 .active.center {
  max-width:430px !important;
  height: 430px !important;
  opacity: 1 !important;
  border-radius: 20px !important;
  /* background-color: red;  */
  overflow: hidden;
  /* bor der: 2px solid red; */
}
.imagecolor-ctrl{
  background-color: red;
  position: absolute;
}
.mat-mdc-form-field-type-mat-native-select .mat-mdc-form-field-infix::after {
  content: "";
  width: 0;
  height: 0;
  border-left: 5px solid rgba(0, 0, 0, 0);
  border-right: 5px solid rgba(0, 0, 0, 0);
  border-top: 5px solid;
  position: absolute;
  right: 0;
  top: 34% !important;
  margin-top: -2.5px;
  pointer-events: none;
}
.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: #fbfbfb;
  border-radius: 5px;
}

.owl-carousel .owl-item img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  object-fit: cover;
}
.carousel_2 .active.center+.active img,
.carousel_2 .active:first-child img,
.carousel_2 :not(.active)+.active img{  
  opacity: 0.3;
  /* background-color: rgba(0, 0, 0, 0.2) !important; */
  background-color: #000;
}

.carousel_2 .owl-dots {
  display: none !important;
}

.owl-prev {
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
}

.owl-next {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
}

.carousel_2 .current {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.carousel_2 .current:hover {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: scale(0.8);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
    transform: scale(1);
  }

  100% {
    opacity: 0;
    transform: scale(0.8);
  }
}

.fadeIn {
  animation: fadeIn 0.5s ease-in-out;
}

.fadeOut {
  animation: fadeOut 0.5s ease-in-out;
}


.owl-theme .owl-nav [class*=owl-]{
  margin: 5px 5%;
  width: 50px;
  height: 50px;
  background-color: #FFF !important;
  border-radius: 50px;
}
.owl-theme .owl-nav [class*=owl-]:hover{
  color: #000;
  background-color: #869791;
}
.owl-theme .fa-chevron-left, .owl-theme .fa-chevron-right{
  
}

.mat-mdc-tab-labels {
  display: flex;
  width: 55% !important;
  margin-left: 1% !important;
}

/*
@media (max-width: 1700px) {
  .owl-carousel .owl-stage {
    max-height: 500px;
  }

  .carousel_2 .active.center+.active,
  .carousel_2 .active:first-child,
  .carousel_2 :not(.active)+.active {
    height: 400px;
    width: 400px !important;
    opacity: 0.5;
  }

  .carousel_2 .active.center {
    width: 500px !important;
    height: 500px;
  }
} */


/* @media (max-width: 1400px) {
  .owl-carousel .owl-stage {
    left: 18px;
  }
}
@media (max-width: 1400px) {
  .owl-carousel .owl-stage {
    left: 18px;
  }
} */
@media (min-width: 1500px) {
  .align-carousel{
    margin-left: 1%;
  }
  .carousel_2 .active.center+.active,
  .carousel_2 .active:first-child,
  .carousel_2 :not(.active)+.active {
    /* width: 6.5% !important; */
    margin-right: 2%!important;
  }
  .carousel_2 .active.center {
    /* width: 8% !important; */
    margin-right: 2%!important;
  }

}
@media (max-width: 1400px) {
  
  .owl-carousel .owl-stage {
    left: 18px;
  }
  .owl-carousel{
    width:100%;
    max-width:100%;
  }
  /* .owl-carousel .owl-stage {
    max-height: 100%;
  } */

  .carousel_2 .active.center+.active,
  .carousel_2 .active:first-child,
  .carousel_2 :not(.active)+.active {
    /* height: 250px;
    width: 250px !important;
    opacity: 0.5; */
    /* width: 6% !important; */
    max-width: 340px !important;
    height: 340px !important;
    margin-right: 3%!important;
  }

  .carousel_2 .active.center {
    /* width: 300px !important;
    height: 300px; */
    /* width: 8% !important; */
    max-width: 430px !important;
    height: 430px !important;
    margin-right: 3%!important;
  }
  /* .align-carousel{
    margin-left: 2% !important;
  } */
}

@media (max-width: 991px) {
  .align-carousel{
    margin-left: 3% !important;
  }
  .owl-carousel .owl-stage {
    left: 30px;
  }
  .carousel_2 .active.center+.active,
  .carousel_2 .active:first-child,
  .carousel_2 :not(.active)+.active {
    max-width: 240px !important;
    height: 240px !important;
    /* width: 6% !important; */
    margin-right: 3%!important;
  }
  .carousel_2 .active.center {
    max-width: 300px !important;
    height: 300px !important;
    /* width: 7% !important; */
    margin-right: 3%!important;
  }
  /* .align-carousel{
    margin-left: 0 !important;
  } */
}

@media (max-width: 767px) {
  .owl-carousel .owl-stage {
    max-height: 300px;
    left: 5%;
  }

  .carousel_2 .active.center+.active,
  .carousel_2 .active:first-child,
  .carousel_2 :not(.active)+.active {
    height: 200px !important;
    max-width: 200px !important;
    opacity: 0.5;
    margin-right: 1%;
  }
  .carousel_2 .active.center {
    max-width: 230px !important;
    height: 280px !important;
    margin-right: 1%;
  } 
  .owl-theme .owl-nav [class*=owl-]{
    margin: 5px 4%;
    width: 30px;
    height: 30px;
  }
}
@media (max-width: 700px) {
  .owl-carousel .owl-stage {
    left: 2%;
  }
}
@media (max-width: 660px) {
  .owl-carousel .owl-stage {
    max-height: 300px;
    left: 1%;
  }
  .carousel_2 .active.center+.active,
  .carousel_2 .active:first-child,
  .carousel_2 :not(.active)+.active {
    margin-right: 1%;
  }
  .carousel_2 .active.center {
    margin-right: 1%;
  }
}
@media (max-width: 575px) {
  .owl-carousel .owl-stage {
    max-height: 300px;
    left: 3%;
  }
  .carousel_2 .active.center+.active,
  .carousel_2 .active:first-child,
  .carousel_2 :not(.active)+.active {
    /* height: 170px !important;
    max-width: 170px !important;
    margin-right: 4%!important; */
    width: 130px !important;
    max-width:100%;
    height: 130px !important;
    margin-right: 2%!important;
  }
  .carousel_2 .active.center {
    /* max-width: 230px !important;
    height: 230px !important;
    margin-right: 4%!important;*/
    width: 170px !important;
    max-width:100%;
    height: 170px !important;
    margin-right: 2%!important;
  }  
}
@media (max-width: 480px) {
  .owl-carousel .owl-stage {
    /* left: 1%; */
  }
  .carousel_2 .active.center+.active,
  .carousel_2 .active:first-child,
  .carousel_2 :not(.active)+.active {
    height: 140px !important;
    width: 90px !important;
    max-width: 100%;
    margin-right: 1%!important;
  }
  .carousel_2 .active.center {
    width: 150px !important;
    max-width: 100%;
    height: 200px !important;
    margin-right: 1%!important;
  } 
  .align-carousel {
    margin-left: 0 !important;
  }
}
@media (max-width: 475px) {
  .owl-carousel .owl-stage {
    left: 1%;
  }
  .carousel_2 .active.center+.active,
  .carousel_2 .active:first-child,
  .carousel_2 :not(.active)+.active {
    height: 130px !important;
    max-width: 100px !important;
    margin-right: 4%!important;
  }
  .carousel_2 .active.center {
    max-width: 120px !important;
    height: 200px !important;
    margin-right: 4%!important;
  } 
}
@media (max-width: 445px) {
  .owl-carousel .owl-stage {
    left: 1%;
  }
  .carousel_2 .active.center+.active,
  .carousel_2 .active:first-child,
  .carousel_2 :not(.active)+.active {
    margin-right: 3%!important;
  }
  .carousel_2 .active.center {
    margin-right: 2%!important;
  } 
}
